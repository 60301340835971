import { Business, Recommend } from "@mui/icons-material";
import { Theme, useTheme } from "@mui/material/styles";;

// Define SupportedMethods type as filter type of methods axios use case.
export enum SupportedMethods {
    get = "get",
    post = "post",
    put = "put",
    delete = "delete",
    patch = "patch"
};
export const baseUrl = "https://admin.fullylinked.com"
// export const wsBaseUrl = "wss://nesta-api.niktech.org/ws"
// export const baseUrl = "https://tb-api.niktech.org"
// export const wsBaseUrl = "wss://tb-api.niktech.org/ws"
// Put your new endpoint like here.
export type TypeQueryParams = {
    [key: string]: string | number | boolean;
};
export const HandlerLangUrl = (url: string, mTheme: any, params?: TypeQueryParams): string => {
    if (params) {
        const searchParams = new URLSearchParams(
            Object.entries(params).map(([key, value]) => [key, String(value)])
        );
        return (`${baseUrl}${mTheme.direction === "rtl" ? "/fa" : ""}${url}?${searchParams.toString()}`)
    }
    else {
        return (`${baseUrl}${mTheme.direction === "rtl" ? "/fa" : ""}${url}`)
    }
}
export interface interfaceUrl {
    url: string;
    method: SupportedMethods;
    isTokenNecessary: boolean;
    isFileInclude: boolean;

}
export const urlsList = {

    auth: {
        login: {
            login: { url: `/api/login_content_panel`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        },
        register: {
            generateOTP: { url: `/users/auth/generate-otp`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },

        },
        refreshToken: { url: `/accounts/users/auth/refresh`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        setPassword: { url: `/users/auth/set-password`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },

    },
    panel: {
        location: {
            getAllCountry: { url: `/api/search-country`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getAllState: { url: `/api/search_state_by_country`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getAllCity: { url: `/api/search_city_by_state`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getAllRegions: { url: `/api/search_regions_by_country`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
        },
        guide: {
            getListCategory: { url: `/api/guide_categories`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getListOfSimilarGuide: { url: `/api/find_similar_guide`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            createGuide: { url: `/api/create_guide_content`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
        },
        event: {
            getListCategory: { url: `/api/event_categories`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getListOfSimilarEvent: { url: `/api/find_similar_events`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            createEvent: { url: `/api/create_event_content`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
        },
        business: {
            getListCategory: { url: `/api/business_categories`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            getListOfSimilarBusiness: { url: `/api/find_similar_business`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            createBusiness: { url: `/api/create_business_content`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
        }
    }
};