import { useDispatch, useSelector } from "react-redux";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { MainCard } from "../../../../components/basic/MainCard/MainCard";
import { InterfaceCreateEvent } from "./InterfaceCreateEvent";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { Box, Grid, Grid2, styled, TextField, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { InterfaceNTTUser } from "../../../../NTTs/InterfaceNTTUser";
import TextFieldSimple from "../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import SelectYesOrNo from "../../../../components/complex/SelectYesOrNo/SelectYesOrNo";
import ButtonSave from "../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import ButtonColor from "../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import Colors, { ColorThemForButton } from "../../../../config/Colors";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import ParentWithHeightAnimation from "../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { EnumStateFetchList } from "../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import InfiniteScrollList from "../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import { InterfaceNTTCategoryEvent } from "../../../../NTTs/InterfaceNTTCategoryEvent";
import { InterfaceNTTEvent } from "../../../../NTTs/InterfaceNTTEvent";
import SelectCategoryEvent from "../../../../components/complex/SelectCategoryEvent/SelectCategoryEvent";
import DatePickerSimple from "../../../../components/basic/Dates/DatePickerSimple/DatePickerSimple";
import { InterfaceNTTOriginCountry } from "../../../../NTTs/InterfaceNTTOriginCountry";
import { InterfaceNTTRegion } from "../../../../NTTs/InterfaceNTTRegion";
import SelectOriginCountry from "../../../../components/complex/SelectOriginCountry/SelectOriginCountry";
import SelectRegion from "../../../../components/complex/SelectRegion/SelectRegion";
import FilesDragAndDrop from "../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import ListFiles from "../../../../components/complex/ListFiles/ListFiles";
import { InterfaceFile } from "../../../../components/complex/ListFiles/InterfaceListFiles";
import { AttachFileRounded, PausePresentationRounded } from "@mui/icons-material";
import SubTitleSection from "../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import AutoCompleteLocation from "../../../../components/complex/AutoCompleteLocation/AutoCompleteLocation";
import { InterfaceSuggestionLocation } from "../../../../components/complex/AutoCompleteLocation/InterfaceAutoCompleteLocation";
import SelectState from "../../../../components/complex/SelectState/SelectState";
import { InterfaceNTTState } from "../../../../NTTs/InterfaceNTTState";
import { InterfaceNTTCity } from "../../../../NTTs/InterfaceNTTCity";
import SelectCity from "../../../../components/complex/SelectCity/SelectCity";
import SelectTimeZone from "../../../../components/complex/SelectTimeZone/SelectTimeZone";
import { InterfaceNTTTimeZone, SelectedTimeZone } from "../../../../NTTs/InterfaceNTTTimeZone";
import MultiSelectorRegion from "../../../../components/complex/MultiSelectorRegion/MultiSelectorRegion";
import moment from "moment";
import timezone from "moment-timezone";
function CreateEvent(paramsComponent: InterfaceCreateEvent) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getTitle, setTitle] = useState<string>();
    const [getWebsite, setWebsite] = useState<string>();
    const [getDetails, setDetails] = useState<string>();
    const [getStartDateTime, setStartDateTime] = useState<number | undefined>();
    const [getEndDateTime, setEndDateTime] = useState<number | undefined>();
    const [getTimeZone, setTimeZone] = useState<InterfaceNTTTimeZone>();
    const [getCategory, setCategory] = useState<InterfaceNTTCategoryEvent>();
    const [getIsOnline, setIsOnline] = useState<boolean>(false);
    const [getOriginCountry, setOriginCountry] = useState<InterfaceNTTOriginCountry>();
    const [getCountry, setCountry] = useState<InterfaceNTTOriginCountry>();
    const [getRegions, setRegions] = useState<InterfaceNTTRegion[]>([]);
    const [getState, setState] = useState<InterfaceNTTState>();
    const [getCity, setCity] = useState<InterfaceNTTCity>();
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getResponseAllCategory, setResponseAllCategory] = useState<InterfaceNTTCategoryEvent[]>([]);
    const [getResponseAllSimilarEvent, setResponseAllSimilarEvent] = useState<InterfaceNTTEvent[]>([]);
    const [getAttachFile, setAttachFile] = useState<InterfaceFile[]>([]);
    const [getLocation, setLocation] = useState<InterfaceSuggestionLocation>();
    const clearForm = () => {
        setTitle(undefined);
        setWebsite(undefined);
        setDetails(undefined);
        setStartDateTime(undefined);
        setEndDateTime(undefined);
        setTimeZone(undefined);
        setCategory(undefined);
        setIsOnline(false);
        setStateFetchList(EnumStateFetchList.initiate);
        setResponseAllSimilarEvent([]);
        setAttachFile([]);
        setLocation(undefined);
        setOriginCountry(undefined);
        setRegions([]);
        setCountry(undefined);
        setState(undefined);
        setCity(undefined);
    }
    const convertUTCDateWithTimeZone = (
        date: string,
        dateFormat: string,
        timeZone: string
    ) => {
        let inputFormat = "MM/DD/YYYY h:mm:ss A"; // must match the input
        let outPutFormat = "YYYY-MM-DD HH:mm";
        let input = moment(date).format(inputFormat);
        let zone = timeZone;
        // construct a moment object
        let m = timezone.tz(input, inputFormat, zone);
        // convert it to utc
        m.utc();
        // format it for output
        let finalUTCTime = m.format(dateFormat ? dateFormat : outPutFormat);
        return finalUTCTime;
    };
    const callApiForCreateEvent = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.event.createEvent;
        let objectReq = new FormData();
        (getAttachFile.length > 0) && objectReq.append("photo", getAttachFile[0].file);
        (getAttachFile.length > 0) && objectReq.append("cover_photo", getAttachFile[0].file);
        getLocation && objectReq.append("latitude", getLocation?.latitude);
        getLocation && objectReq.append("longitude", getLocation?.longitude);
        objectReq.append("sub_location", getIsOnline ? "" : (getLocation?.formattedAddress ?? ""));
        objectReq.append("location", (getLocation?.completeAddress ?? getLocation?.formattedAddress ?? ""));
        objectReq.append("host_user_id", `${profileInStore.idUser}`);
        objectReq.append("title", getTitle ?? "");
        getWebsite && objectReq.append("web_url", getWebsite);
        objectReq.append("details", getDetails ?? "");
        if (getStartDateTime && getTimeZone) {

            const tempSelectedTimeZone: SelectedTimeZone = {
                abbr: getTimeZone.abbr,
                utc: getTimeZone.utc[0],
            };
            // const date = new Date(getStartDateTime * 1000 + ((getTimeZone?.offset ?? 0) * 60 * 60 * 1000));
            // // Get the ISO string and slice it to remove the milliseconds part
            // const isoString = date.toISOString().slice(0, 19);
            // objectReq.append("start_date_time", `${isoString}`);
            objectReq.append("start_date_time", convertUTCDateWithTimeZone(
                new Date(getStartDateTime * 1000).toString(),
                "",
                tempSelectedTimeZone.utc
            ));
        }
        if (getEndDateTime && getTimeZone) {
            const tempSelectedTimeZone: SelectedTimeZone = {
                abbr: getTimeZone.abbr,
                utc: getTimeZone.utc[0],
            };
            // const date = new Date(getStartDateTime * 1000 + ((getTimeZone?.offset ?? 0) * 60 * 60 * 1000));
            // // Get the ISO string and slice it to remove the milliseconds part
            // const isoString = date.toISOString().slice(0, 19);
            // objectReq.append("start_date_time", `${isoString}`);
            objectReq.append("end_date_time", convertUTCDateWithTimeZone(
                new Date(getEndDateTime * 1000).toString(),
                "",
                tempSelectedTimeZone.utc
            ));
        }

        objectReq.append("is_online", `${getIsOnline ? 1 : 0}`);
        getOriginCountry && objectReq.append("origin_country", `${getOriginCountry.id}`);
        getRegions && objectReq.append("region", `${(getRegions.map(reg => reg.id).join(","))}`);
        getCountry && objectReq.append("country", `${getCountry.id}`);
        getState && objectReq.append("state", `${getState?.idx ?? -1}`);
        getCity && objectReq.append("city", `${getCity?.idx ?? -1}`);
        getCategory && objectReq.append("category_id", `${getCategory?.id}`);
        objectReq.append("posted_by_id", `${profileInStore.idUser}`);
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, objectReq, profileInStore, true)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                clearForm();
                // setResponseAllCategory(t);

                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "New Event Added", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (e.data && e.data.statuscode && e.data.statuscode !== 200) {
                    dispatch(ShowAlert({
                        text: `${e.data.msg}`,
                        typeAlert: enumTypeOfAlert.error,
                        lastTimeecondsUpdate: (new Date()).getSeconds()
                    }));
                }
                else if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForGetListCategory = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.event.getListCategory;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response.category as (InterfaceNTTCategoryEvent[]);
                setResponseAllCategory(t);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForGetAllEventSimilar = async () => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.event.getListOfSimilarEvent;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, { title: getTitle } as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, false)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response.events as (InterfaceNTTEvent[]);
                setResponseAllSimilarEvent(t)
                if (t.length > 0) {
                    setStateFetchList(EnumStateFetchList.foundData);
                }
                else {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }

                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "Fetched.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                setStateFetchList(EnumStateFetchList.errorInRequest);
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (e.data && e.data.statuscode && e.data.statuscode !== 200) {
                    dispatch(ShowAlert({
                        text: `${e.data.msg}`,
                        typeAlert: enumTypeOfAlert.error,
                        lastTimeecondsUpdate: (new Date()).getSeconds()
                    }));
                }
                else if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    useEffect(() => {
        callApiForGetListCategory();
    }, [])
    const TitleOfCard = styled("h2")(({ theme }) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        boxSizing: "border-box",
        color: Colors.theme.blue.light,
        fontFamily: confComps.font.poppins.SemiBold,
        fontSize: '1.1rem',

    }));
    return (
        <ContainerPage title={confComps.translate.addEvent}>
            <MainCard>
                <Box sx={{
                    marginInline: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                    // marginInlineEnd: { xs: 0, sm: 16, md: 24, lg: 32, xl: 40 },
                    boxSizing: "border-box",


                    display: 'flex',
                    flexDirection: 'row',  // You can change this to 'column' for vertical layout
                    gap: mTheme.spacing(2), // This replaces Grid's spacing prop
                    flexWrap: 'wrap',      // Optional: Wrap items in a new line if there's not enough space
                }}
                >
                    <Grid2 container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 22, xl: 30 }}
                        rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                    >

                        <Grid2 size={2} >
                            <TextFieldSimple title={confComps.translate.title}
                                textValue={getTitle ?? ""} onChangeValue={setTitle}
                            />
                        </Grid2>


                        <Grid2 size={2} >
                            <SelectCategoryEvent
                                onChangeSelectedCategory={setCategory}
                                defaultSelected={getCategory}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <SelectYesOrNo onChangeSelectYesOrNo={(isPrivate) => {
                                setIsOnline(isPrivate ?? false);
                            }}
                                title={confComps.translate.isOnline}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <TextFieldSimple title={confComps.translate.website}
                                textValue={getWebsite ?? ""} onChangeValue={setWebsite}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <SelectTimeZone
                                onChangeSelectedState={(selected?: InterfaceNTTTimeZone) => {
                                    setTimeZone(selected);
                                }}
                                defaultSelected={getTimeZone}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <DatePickerSimple
                                title={confComps.translate.startDate}
                                onCloseDialogDate={() => { }
                                }
                                onOpenDialogDate={() => { }
                                }
                                onChangeValue={(timeSecond: number | undefined) => {
                                    setStartDateTime(timeSecond);
                                }}
                                defaultTimeInSecond={getStartDateTime}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <DatePickerSimple
                                title={confComps.translate.endDate}
                                onCloseDialogDate={() => { }
                                }
                                onOpenDialogDate={() => { }
                                }
                                onChangeValue={(timeSecond: number | undefined) => {
                                    setEndDateTime(timeSecond);
                                }}
                                defaultTimeInSecond={getEndDateTime}
                            />
                        </Grid2>

                        <Grid2 size={2} >
                            <SelectOriginCountry
                                onChangeSelectedOriginCountry={setOriginCountry}
                                defaultSelected={getOriginCountry}

                            />
                        </Grid2>

                        <Grid2 size={2} >
                            <MultiSelectorRegion
                                onChangeSelectedRegion={(selected: InterfaceNTTRegion[]) => {
                                    setRegions(selected);
                                }}
                                defaultSelected={getRegions}
                                origin_country={getOriginCountry}
                            />
                        </Grid2>

                        <Grid2 size={2} >
                            <SelectOriginCountry
                                title={confComps.translate.country}
                                onChangeSelectedOriginCountry={setCountry}
                                defaultSelected={getCountry}

                            />
                        </Grid2>

                        <Grid2 size={2}>
                            <SelectState
                                onChangeSelectedState={(select) => {
                                    setState(select)
                                }}
                                defaultSelected={getState}
                                country={getCountry}
                            />
                        </Grid2>
                        <Grid2 size={2}>
                            <SelectCity
                                onChangeSelectedCity={(select) => {
                                    setCity(select)
                                }}
                                defaultSelected={getCity}
                                state={getState}
                                country={getCountry}
                            />
                        </Grid2>
                        <Grid2 size={2}>

                            <AutoCompleteLocation
                                onPlaceSelected={(place: InterfaceSuggestionLocation) => {
                                    setLocation(place)
                                }}
                                defaultValue={getLocation}
                                title="Location"
                                isSearchByStreet={true}
                            // title={confComps.translate.location}
                            // textValue={getLocation ?? ""}
                            // onChangeValue={setLocation}
                            />

                        </Grid2>
                        <Grid2 size={4}>
                            <SubTitleSection title={confComps.translate.uploadImage} />
                            <FilesDragAndDrop
                                onUpload={(files) => {
                                    setAttachFile(files.map((fileNew, indx) => {
                                        return (
                                            {
                                                file: fileNew,
                                            }
                                        )
                                    }));
                                }}
                                count={1}
                                formats={['jpg', 'png']}
                                containerStyles={{
                                    borderRadius: "4px",
                                    boxSizing: "border-box",

                                }}
                                openDialogOnClick
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: `1px dotted ${Colors.theme.blue.blue}`,
                                    cursor: "pointer",
                                    paddingInline: "1.6rem",
                                    paddingBlock: "1.2rem",
                                    background: "#E3F9E9",
                                    borderRadius: "0.8rem",
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                    }}>
                                        <AttachFileRounded style={{ color: Colors.theme.blue.dark }} />
                                        {confComps.translate.dragDropFiles}
                                    </div>
                                    <div style={{
                                        borderRadius: "0.4rem", background: Colors.theme.blue.light_2,
                                        paddingInline: "0.5rem",
                                        paddingBlock: "0.1rem",
                                        boxSizing: "border-box",
                                        border: `1px solid ${Colors.theme.blue.dark}`,
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        lineHeight: "1.2rem",
                                        color: Colors.theme.blue.dark,

                                    }}>
                                        {"Choose File"}
                                    </div>

                                </div>
                            </FilesDragAndDrop>
                            <div style={{ marginTop: "2rem" }}>
                                <ListFiles Files={getAttachFile}
                                    callbackRemoveFiles={(index: number) => {
                                        setAttachFile(prev => prev.filter((prev, indexP) => indexP !== index));
                                    }}
                                    callBackUpdatedAllFiles={setAttachFile}
                                />

                            </div>

                        </Grid2>
                        <Grid2 size={4} >
                            <TextFieldMultiLine title={confComps.translate.description}
                                onChangeText={setDetails} text={getDetails ?? ""}
                            />
                        </Grid2>
                        <Grid2 size={4} >
                            <div style={{
                                width: "100%", alignItems: "center", display: 'flex', flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <ButtonColor text={confComps.translate.showSimilar}
                                    iconStart={<ArrowDropDownIcon />}
                                    onclick={() => {
                                        callApiForGetAllEventSimilar();
                                    }}
                                    color={ColorThemForButton.orangeColorAndTransparentBackground}
                                />
                                <ButtonSave text={confComps.translate.addEvent}
                                    callbackOnClick={() => {
                                        callApiForCreateEvent();
                                    }}
                                />

                            </div>
                        </Grid2>
                    </Grid2>



                </Box>
                <ParentWithHeightAnimation in={getStateFetchList !== EnumStateFetchList.initiate} timeout={300} style={{
                    gap: "0.5rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }} >
                    <InfiniteScrollList
                        isDataExist={(getResponseAllSimilarEvent).length !== 0}
                        onEndReached={async () => {
                            // let oldPage = getPaginationForLastRequest;
                            // oldPage = {
                            //     ...oldPage,
                            //     page: (oldPage.page + 1)
                            // };
                            // setPaginationForLastRequest(oldPage);
                            // console.log("callback for change state response2:", oldPage);
                            // callApiForGetForeignCompanies(oldPage);
                        }}
                        onRetryClicked={() => {
                            callApiForGetAllEventSimilar();
                        }}
                        stateResponseList={getStateFetchList}
                        styleSpacingMUI={{
                            columns: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 },
                            rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                            columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                        }}

                    >
                        <div style={{
                            display: 'flex', width: '100%', flexDirection: "column",
                            alignItems: 'center',
                            gap: "0.9rem"
                        }}>



                            {getResponseAllSimilarEvent.map((eventSim, index, arr) => {
                                return (

                                    <MainCard >
                                        <Grid2 container
                                            justifyContent="space-between" alignItems="flex-start"
                                            columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 22, xl: 30 }}
                                            rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                                            columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                                        >
                                            <Grid2 size={2} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {"title :"}
                                                    </TitleOfCard>
                                                    {eventSim.title}
                                                </div>

                                            </Grid2>
                                            <Grid2 size={2} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {"category :"}
                                                    </TitleOfCard>
                                                    {getResponseAllCategory.filter(cat => cat.id === eventSim.category_id).map((cat => cat.name)).join(",")}
                                                </div>
                                            </Grid2>
                                            <Grid2 size={2} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {`Is Online:`}
                                                    </TitleOfCard>
                                                    {eventSim.is_online === 1 ? "YES" : "NO"}
                                                </div>
                                            </Grid2>
                                            <Grid2 size={4} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {"description: "}
                                                    </TitleOfCard>
                                                    {eventSim.details}
                                                </div>
                                            </Grid2>
                                        </Grid2>
                                    </MainCard>

                                )
                            })}
                        </div>

                    </InfiniteScrollList>

                </ParentWithHeightAnimation>
            </MainCard >

        </ContainerPage >
    );
}
export default CreateEvent;