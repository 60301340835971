import { ConfigComps } from "../../../config/ConfigCOMP";
import { useTheme } from "@mui/material";
import { styled as styledMUI } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { useDispatch } from "react-redux";
import { InitialProfilesState, SetTokenAndRefreshToken } from "../../../store/profileStateSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { InterfaceAPICallLogin, InterfaceLoginPage } from "./InterfaceLoginPage";
import Links from "../../../config/Links";
import Colors from "../../../config/Colors";
import TextFieldWithIconBox from "../../../components/basic/TextField/TextFieldWithIconBox/TextFieldWithIconBox";
import { WelcomeIcon } from "../../../assets/icons/svg/WelcomeIcon";
import { EnvelopeIcon } from "../../../assets/icons/svg/EnvelopeIcon";
import { LockIcon } from "../../../assets/icons/svg/LockIcon";
import ButtonEffectShadow from "../../../components/basic/Buttons/ButtonEffectShadow/ButtonEffectShadow";

const DivAllTextContainer = styledMUI("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    // background:"red",
    // paddingInline: "5%",
    paddingTop: "calc(48px + 2vh)",
    justifyContent: "space-around",
    zIndex: 100,
    gap: "calc(0.1vh + 1.92rem)",
    position: "absolute",
    top: "50%",
    left: "48%",
    width: "55%",
    minWidth: "270px",
    transform: "translate(-55%,-60%)",
    alignItems: "center",
    // background: "blue",

    // [theme.breakpoints.down('md')]: {
    //     maxWidth: "80%",
    //     minWidth: "50%",
    //     // transform: "translate(0,-50%)",
    // },
    // [theme.breakpoints.up('md')]: {

    //     maxWidth: "40%",
    //     minWidth: "30%",
    //     // right: theme.direction == "ltr" ? 0 : undefined,
    //     // left: theme.direction == "ltr" ? undefined : 0,
    //     // transform: theme.direction == "ltr" ? "translate(-12.5%,-50%)" : "translate(12.5%,-50%)",
    // }

}));



function LoginPage(pageParams: InterfaceLoginPage) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const [getEditTextUser, setEditTextUser] = useState<string>("");
    const [getEditTextPassword, setEditTextPassword] = useState<string>("");


    const callAPIForGetToken = async (): Promise<InitialProfilesState | undefined> => {
        dispatch(isVisibilityProgressBar(true));

        dispatch(isVisibilityProgressBar(true));
        const dataLogin = {
            email: getEditTextUser,
            content_panel_pass: getEditTextPassword,
        };

        var urlSelected = urlsList.auth.login.login;

        try {
            const t: any = await RequestHandler(
                dispatch,
                HandlerLangUrl(urlSelected.url, mTheme),
                urlSelected.method,
                urlSelected.isTokenNecessary,
                dataLogin
            );

            console.log("response:) login page:");
            console.log(t);
            const response = t as InterfaceAPICallLogin;

            const initialProfilesState: InitialProfilesState = {
                token: response.token,
                idUser: response.user.id,
                refreshToken: response.token,
                lastUpdatePosixTime: Date.now(),
            };

            dispatch(SetTokenAndRefreshToken(initialProfilesState));
            dispatch(isVisibilityProgressBar(false));
            return initialProfilesState;
        } catch (e: any) {
            console.log("response:(");
            console.error(e);
            dispatch(isVisibilityProgressBar(false));
            if (e.data && e.data.statuscode && e.data.statuscode !== 200) {
                dispatch(ShowAlert({
                    text: `${e.data.msg}`,
                    typeAlert: enumTypeOfAlert.error,
                    lastTimeecondsUpdate: (new Date()).getSeconds()
                }));
            }
            else if (false && isAnySimilarInterfaceResponseErrorFromServer(e)) {
                dispatch(ShowAlert({
                    text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`,
                    typeAlert: enumTypeOfAlert.error,
                    lastTimeecondsUpdate: (new Date()).getSeconds()
                }));
            } else {
                dispatch(ShowAlert({
                    text: `لطفا به ما خبر دهید`,
                    typeAlert: enumTypeOfAlert.error,
                    lastTimeecondsUpdate: (new Date()).getSeconds()
                }));
            }

            return undefined;
        }
    }
    // const callAPIForGetMyProfile = async (token: string) => {
    //     dispatch(isVisibilityProgressBar(true));
    //     var urlSelected = urlsList.panel.l.getInformationOfMyProfile;

    //     await RequestHandler(dispatch, HandlerLangUrl(urlSelected.url, mTheme),
    //         urlSelected.method, urlSelected.isTokenNecessary, undefined, undefined, false, token)
    //         .then((response: any) => {
    //             console.log("response:)");
    //             console.log(response);
    //             let t = response as InterfaceAPICallGetMyProfileInfo;
    //             dispatch(ShowAlert({
    //                 text: "Hello" + ` ${t.user.first_name} ${t.user.last_name}` + ".",
    //                 typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds()
    //             }));
    //             navigate(Links.screens.panel.guide.addGuide);
    //             dispatch(isVisibilityProgressBar(false));

    //         })
    //         .catch((e: any) => {
    //             console.log("response:(");
    //             console.error(e);
    //             dispatch(isVisibilityProgressBar(false));
    //             if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
    //                 dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
    //             }
    //             else {
    //                 dispatch(ShowAlert({ text: `2لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
    //             }
    //         });


    // }
    const handleClickOnSubmit = () => {
        callAPIForGetToken().then((result: (InitialProfilesState | undefined)) => {
            if (result) {
                console.log("result last function is:", result);
                dispatch(ShowAlert({
                    text: "Hello",
                    typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds()
                }));
                navigate(Links.screens.panel.guide.addGuide);
                dispatch(isVisibilityProgressBar(false));

                // callAPIForGetMyProfile(result.token)
            }
        })
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100vw",
            height: "100vh",
            overflow: "auto",
            alignItems: "start",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundImage: "green",
            backgroundRepeat: "no-repeat",
            boxSizing: "border-box",
            background: Colors.theme.white.PrimaryLight,
        }}>

            <div style={{
                position: "relative",
                display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
                flexGrow: "1",
                flexBasis: "0",
                height: "100%",
                boxSizing: "border-box",
                // background: "red",
            }}>




                <DivAllTextContainer>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: "12px" }}>
                        <div style={{
                            display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "center",
                            width: "100%",
                        }}>

                            <h1 style={{
                                fontFamily: confComps.font.poppins.Light,
                                fontSize: "3rem",
                                // lineHeight: "2.8rem",
                                textWrap: "nowrap",
                                color: Colors.theme.mainColor.primary,
                            }}>{"FullyLinked"}
                            </h1>
                        </div>
                        <h1 style={{
                            width: "100%", color: Colors.theme.blue.blue, textWrap: "nowrap", fontFamily: confComps.font.poppins.Regular,
                            textAlign: "center", fontSize: "1.6rem",
                            lineHeight: "2.4rem",
                            marginBottom: "24px",


                        }}>
                            {"Content Panel"}
                        </h1>
                    </div>
                    <TextFieldWithIconBox
                        iconStart={EnvelopeIcon}
                        styleMain={{
                            borderRadius: "0.5rem",
                            width: "100%",
                            color: Colors.theme.blue.dark,
                            background: Colors.theme.white.white90,
                        }}
                        hintText={confComps.translate.email}

                        onChangeValue={setEditTextUser}
                        isDataMustLTR={true}
                        isSmallHintShow={false}
                    />
                    <div style={{
                        width: "100%", alignItems: "end", display: "flex", flexDirection: "column",
                        gap: "8px",
                        // background: "red",
                    }}>
                        <TextFieldWithIconBox
                            styleMain={{
                                borderRadius: "0.5rem",
                                width: "100%",
                                color: Colors.theme.blue.dark,
                                background: Colors.theme.white.white90,

                            }}
                            iconStart={LockIcon}

                            hintText={confComps.translate.password}
                            // sizeWidth="small"
                            onChangeValue={setEditTextPassword}
                            type="password"

                            isDataMustLTR={true}
                            isSmallHintShow={false} />
                        {/* <p style={{
                            color: Colors.theme.orange.light,
                            fontFamily: confComps.font.poppins.SemiBold,
                            fontSize: "1.12rem",
                            lineHeight: "1.68rem",
                        }}>
                            {confComps.loginAndRegister.loginPage.pageInfo.password.forgetPassword}
                        </p> */}
                    </div>
                    <ButtonEffectShadow
                        styleMainDiv={{
                            width: "100%",
                            marginTop: "1.92rem",
                            borderRadius: "0.5rem",
                        }}
                        onclick={handleClickOnSubmit}
                        text={confComps.translate.submit}
                        colors={Colors.buttonShadow.backgroundDarkBlue} />
                    {/* <ButtonEffectShadow text={confComps.loginAndRegister.loginPage.pageInfo.button.title} onclick={onClickSubmitHandler} /> */}


                    {/* <TextWithLink list={confComps.panel.loginAndRegister.login.textRedirect} /> */}

                </DivAllTextContainer>
            </div>


        </div>
    )
}

export default LoginPage;