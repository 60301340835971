import React, { useState, useRef, useEffect } from "react";
import { InterfaceAutoCompleteLocation, InterfaceSuggestionLocation } from "./InterfaceAutoCompleteLocation";
import { Emoji } from "emoji-picker-react";
import ReactCountryFlag from "react-country-flag";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { useDispatch } from "react-redux";
import { keyframes, styled, useTheme } from "@mui/material";
import Colors from "../../../config/Colors";
import { CloseRounded, SyncRounded } from "@mui/icons-material";
import LineSeparator from "../../basic/LineSeprator/LineSeparator";
import axios from "axios";

export const GOOGLE_PLACES_API_KEY = '​​AIzaSyBcBEFfhhQuziHUQ9RdFXV8xa98zO8qz8U'; //


const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    gap: "1rem",
    paddingInline: "1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
const AutoCompleteLocation = (paramsComponent: InterfaceAutoCompleteLocation) => {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const [input, setInput] = useState<string>(paramsComponent.defaultValue ? (paramsComponent.defaultValue.completeAddress ?? paramsComponent.defaultValue.formattedAddress) : "");
    const [suggestions, setSuggestions] = useState<InterfaceSuggestionLocation[]>([]);
    const [getSelectedLocation, setSelectedLocation] = useState<InterfaceSuggestionLocation>();
    const [loading, setLoading] = useState<boolean>(false);
    const [getIsFocused, setIsFocused] = useState(false);
    const textInputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (paramsComponent.defaultValue) {
            setInput(paramsComponent.defaultValue.formattedAddress);
        }
    }, [paramsComponent.defaultValue]);

    const fetchSuggestions = async (input: string) => {
        if (!input) {
            setSuggestions([]);
            return;
        }
        setLoading(true);
        // let strURL = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=${GOOGLE_PLACES_API_KEY}&types=(cities)&language=en`;
        let strURL = `https://content.fullylinked.com/flaskapp/api/places/place/autocomplete/json?input=${input}&types=(cities)&language=en`;
        if (paramsComponent.isSearchByStreet) {
            strURL = `https://content.fullylinked.com/flaskapp/api/places/place/autocomplete/json?input=${input}&types=address&language=en`;
        }
        try {
            const autocompleteResponse = await axios.get(strURL);
            const predictions = autocompleteResponse.data.predictions;

            const fetchDetailsPromises = predictions.map(async (prediction: any) => {
                const placeId = prediction.place_id;
                const detailsResponse = await axios.get(
                    `https://content.fullylinked.com/flaskapp/api/places/place/details/json?placeid=${placeId}`
                );

                const detailsJson = detailsResponse.data;
                const addressComponents = detailsJson.result.address_components;

                const city = addressComponents.find((component: any) =>
                    component.types.includes('locality') || component.types.includes('administrative_area_level_3')
                )?.long_name;

                const state = addressComponents.find((component: any) =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name;

                const country = addressComponents.find((component: any) =>
                    component.types.includes('country')
                )?.long_name;

                const isoCode = addressComponents.find((component: any) =>
                    component.types.includes('country')
                )?.short_name.toLowerCase();

                const latitude = detailsJson.result.geometry.location.lat.toString();
                const longitude = detailsJson.result.geometry.location.lng.toString();

                const formattedAddress = `${city}, ${state}, ${country}`;
                const addressComplete = detailsJson.result.formatted_address; // Complete address

                return {
                    place_id: prediction.place_id,
                    description: prediction.description,
                    formattedAddress,
                    completeAddress: addressComplete,
                    countryIsoCode: isoCode,
                    latitude,
                    longitude,
                };
            });

            const results = await Promise.all(fetchDetailsPromises);
            setSuggestions(results);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
        // fetch(
        //     strURL
        // )
        //     .then((response) => response.json())
        //     .then((responseJson) => {
        //         const fetchDetailsPromises = responseJson.predictions.map(async (prediction: any) => {
        //             const placeId = prediction.place_id;
        //             const detailsResponse = await fetch(
        //                 `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${GOOGLE_PLACES_API_KEY}`
        //             );
        //             const detailsJson = await detailsResponse.json();
        //             const addressComponents = detailsJson.result.address_components;
        //             const city = addressComponents.find((component: any) =>
        //                 component.types.includes('locality') || component.types.includes('administrative_area_level_3')
        //             )?.long_name;
        //             const state = addressComponents.find((component: any) =>
        //                 component.types.includes('administrative_area_level_1')
        //             )?.long_name;
        //             const country = addressComponents.find((component: any) =>
        //                 component.types.includes('country')
        //             )?.long_name;
        //             const isoCode = addressComponents.find((component: any) =>
        //                 component.types.includes('country')
        //             )?.short_name.toLowerCase();
        //             const latitude = detailsJson.result.geometry.location.lat.toString();
        //             const longitude = detailsJson.result.geometry.location.lng.toString();
        //             const formattedAddress = `${city}, ${state}, ${country}`;
        //             const addressComplete = detailsJson.result.formatted_address;  // Complete address
        //             return {
        //                 place_id: prediction.place_id,
        //                 description: prediction.description,
        //                 formattedAddress,
        //                 completeAddress: addressComplete,
        //                 countryIsoCode: isoCode,
        //                 latitude,
        //                 longitude
        //             };
        //         });

        //         Promise.all(fetchDetailsPromises).then((results) => {
        //             setSuggestions(results);
        //             setLoading(false);
        //         });
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //         setLoading(false);
        //     });
    };

    const handleInputChange = (text: string) => {
        setInput(text);
        fetchSuggestions(text);
    };

    const handleSuggestionPress = (suggestion: InterfaceSuggestionLocation) => {
        setInput(suggestion.formattedAddress);
        setSuggestions([]);
        paramsComponent.onPlaceSelected(suggestion);
    };
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                if (containerRef.current && getIsFocused && !containerRef.current.contains(event.target as Node)) {
                    setIsFocused(false);
                    // setSearchText(getSelectedCategory?.name ?? "");
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [getIsFocused]);
    useEffect(() => {
        setSelectedLocation(paramsComponent.defaultValue);
        setInput(paramsComponent.defaultValue?.completeAddress ?? paramsComponent.defaultValue?.formattedAddress ?? "")
    }, [paramsComponent.defaultValue])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}
            ref={containerRef}
        >
            <label style={{
                fontWeight: "300",

                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                {paramsComponent.title}
            </label>

            <div style={{
                background: Colors.theme.white.white90,
                border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                borderRadius: '0.4rem',
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.12rem',
                width: "100%",
                color: Colors.theme.blue.dark,
                outline: 'none',
                lineHeight: "1.68rem",
                display: "flex", flexDirection: "row",
                alignItems: 'center',
                paddingInline: '0.64rem',
                boxSizing: "border-box",
            }}>
                {getSelectedLocation && getSelectedLocation.countryIsoCode &&
                    <ReactCountryFlag countryCode={`${getSelectedLocation.countryIsoCode}`} />
                }
                <input
                    ref={textInputRef}
                    onFocus={() => setIsFocused(true)}
                    style={{
                        width: 0,
                        boxSizing: "border-box",
                        paddingInline: '0.64rem',
                        paddingBlock: '0.32rem',
                        flexGrow: "1",
                        cursor: "pointer",
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                        lineHeight: "1.68rem",
                    }}
                    value={input}
                    onChange={(e) => handleInputChange(e.target.value)}
                    placeholder="Search"
                />
                {input.length > 0 && (
                    <CloseRounded style={{ width: 15, height: 15 }}
                        onClick={() => {
                            setInput('');
                            setSuggestions([]);
                        }}
                    />
                )}
            </div>
            <DropdownBox
                isDropdownOpen={getIsFocused || loading}
            >
                {loading &&
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                        <SpinnerIcon />
                    </div>
                }
                {!loading && (suggestions.length === 0) &&
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                        {confComps.translate.notFound}
                    </div>
                }
                {suggestions.length > 0 &&
                    (
                        suggestions
                            .flatMap((country, index, arr) => {
                                if ((index + 1) === arr.length) {
                                    return (
                                        [<RowOfList onClick={() => {
                                            setInput(country.completeAddress ?? country.formattedAddress);
                                            handleSuggestionPress(country)
                                            setIsFocused(false);
                                        }}>

                                            <ReactCountryFlag countryCode={`${country.countryIsoCode}`} />
                                            {country.completeAddress ?? country.formattedAddress}
                                        </RowOfList>
                                        ]
                                    )
                                }
                                return (
                                    [<RowOfList onClick={() => {
                                        setInput(country.completeAddress ?? country.formattedAddress);
                                        handleSuggestionPress(country)
                                        setIsFocused(false);
                                    }}>
                                        <ReactCountryFlag countryCode={`${country.countryIsoCode}`} />
                                        {country.completeAddress ?? country.formattedAddress}
                                    </RowOfList>
                                        ,
                                    <LineSeparator />
                                    ]

                                );
                            })
                        // map((suggestion, index) => (
                        //     <li key={suggestion.place_id} onClick={() =>
                        //         handleSuggestionPress(suggestion)}
                        //         style={{
                        //             display: "flex",
                        //             flexDirection: "row",
                        //             alignItems: "center",
                        //             borderBottomColor: "#ccc",
                        //         }}>
                        //         {suggestion.countryIsoCode && (
                        //             <span style={{ fontSize: 26, marginRight: 5 }}>
                        //                 <ReactCountryFlag countryCode={`${suggestion.countryIsoCode}`} />
                        //             </span>
                        //         )}
                        //         <span>{suggestion.formattedAddress}</span>
                        //     </li>
                        // ))}

                    )
                }
            </DropdownBox>
        </div >
    );
};

const styles = {
    container: {
        backgroundColor: "#ecf0f1",
    },
    textInput: {
        height: 40,
        flexGrow: 1,
        borderColor: "#ccc",
        borderWidth: 1,
        paddingHorizontal: 10,
        color: "black",
        backgroundColor: "#fff",
    },
    suggestion: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        borderBottomColor: "#ccc",
    },
    lastSuggestion: {
        borderBottomWidth: 0,
    },
};


export default AutoCompleteLocation;
