import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ConfigComps } from '../../../config/ConfigCOMP';
import { InterfaceSelectCity } from './InterfaceSelectCity';
import Colors from '../../../config/Colors';
import { keyframes, styled, useTheme } from '@mui/material';
import { SyncRounded } from '@mui/icons-material';
import { isVisibilityProgressBar } from '../../../store/ProgressbarInRootSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedProfile } from '../../../store/profileStateSlice';
import { HandlerLangUrl, TypeQueryParams, urlsList } from '../../../api/UrlsList';
import { RequestHandler } from '../../../api/API';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../api/APIErrorResponse';
import { ShowAlert } from '../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../MUIConfig/alert_popup/interfaceAlertPopup';
import LineSeparator from '../../basic/LineSeprator/LineSeparator';
import { InterfaceNTTCity } from '../../../NTTs/InterfaceNTTCity';
import { InterfaceNTTState } from '../../../NTTs/InterfaceNTTState';
import { InterfaceNTTCountry } from '../../../NTTs/InterfaceNTTCountry';
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled('div')(({ theme }) => ({
    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start',
    fontSize: '1rem',
    width: '100%',
    paddingInline: '1rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
        background: Colors.theme.orange.lemonLight,

    }
}));
export const DropdownBox = styled('div', { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: 'hidden',
            position: 'absolute',
            top: 'calc(100% + 0.5rem)',
            left: '0',
            zIndex: isDropdownOpen ? '3' : '-1',
            width: '100%',
            background: '#F8F8F8',
            marginTop: '-10px',
            boxShadow: '0px 4px 6px rgba(112,119,161,0.3)',
            borderInline: '1px solid #ccc',
            borderBlockStart: '1px solid #DFDFDF',
            borderBlockEnd: '1px solid #ccc',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            borderEndEndRadius: '1rem',
            borderEndStartRadius: '1rem',
            opacity: isDropdownOpen ? 1 : 0,
            transition: 'zIndex,opacity 0.3s ease, transform 0.5s ease',
            transform: isDropdownOpen ? 'translateY(0%)' : 'translateY(-20%)',
            visibility: isDropdownOpen ? 'visible' : 'hidden',
        }
    )
});
function SelectCity(paramsComponent: InterfaceSelectCity) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getResponseAllCity, setResponseAllCity] = useState<InterfaceNTTCity[]>([]);
    const [getSelectedCity, setSelectedCity] = useState<InterfaceNTTCity | undefined>(paramsComponent.defaultSelected);
    const [getSelectedState, setSelectedState] = useState<InterfaceNTTState | undefined>(paramsComponent.state);
    const [getSelectedCountry, setSelectedCountry] = useState<InterfaceNTTCountry | undefined>(paramsComponent.country);
    const [getSearchText, setSearchText] = useState<string>(paramsComponent.defaultSelected?.label ?? '');
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const [getIsFocused, setIsFocused] = useState(false);

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const containerRef2 = useRef<HTMLDivElement>(null);


    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('call changed', event.target.value)
        // Clear the previous timeout if it exists
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // Get the current value from the input
        const { value } = event.target;
        // Set a new timeout
        const newTimeoutId = setTimeout(() => {
            // Call your API function here with the updated value
            if (value.length !== 0) {
                callApiForGetListCities({ state_id: (paramsComponent.state?.idx ?? -1), term: value });
            }
        }, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
        // Update state to store the timeout ID
        setTimeoutId(newTimeoutId);
        // Update the input value state
        setSearchText(value);
    };




    const callApiForGetListCities = async (paramsSearch: TypeQueryParams) => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.location.getAllCity;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, paramsSearch),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                let t = response.cities as (InterfaceNTTCity[]);
                setResponseAllCity(t);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
        setStateIsSearching(false);


    }
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside2 = (event: MouseEvent) => {
                if (containerRef2.current && !containerRef2.current.contains(event.target as Node)) {
                    setIsFocused(false);
                    setSearchText(getSelectedCity?.label ?? '');
                }
            };

            document.addEventListener('mousedown', handleClickOutside2);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside2);
            };
        }
    }, [getIsFocused]);

    useEffect(() => {
        if (paramsComponent.state) {
            // callApiForGetListCities({ state_id: paramsComponent.state.idx, term: '' });
            setSearchText("");
            setResponseAllCity([]);
        }
        else {
            setSearchText("");
        }
        setSelectedState(paramsComponent.state);
    }, [paramsComponent.state])
    useEffect(() => {
        setSelectedCountry(paramsComponent.country);
    }, [paramsComponent.country])
    useEffect(() => {
        getSelectedCity && paramsComponent.onChangeSelectedCity(getSelectedCity);
    }, [getSelectedCity])
    useEffect(() => {
        setSelectedCity(paramsComponent.defaultSelected);
        setSearchText(paramsComponent.defaultSelected?.label ?? "")
    }, [paramsComponent.defaultSelected])

    return (
        <div style={{
            display: 'flex', flexDirection: 'column', position: 'relative', height: '100%', width: '100%',
        }}
            ref={containerRef2}
        >
            <label style={{
                fontWeight: '300',

                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: '1.68rem',
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                {confComps.translate.city}
            </label>
            <div style={{
                background: Colors.theme.white.white90,
                border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                borderRadius: '0.4rem',
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.12rem',
                width: '100%',
                color: Colors.theme.blue.dark,
                outline: 'none',
                lineHeight: '1.68rem',
                display: 'flex', flexDirection: 'row',
                alignItems: 'center',
                paddingInline: '0.64rem',
                boxSizing: 'border-box',
            }}>
                {getSelectedCountry && getSelectedCountry.iso && <img
                    src={`https://flagcdn.com/w320/${getSelectedCountry.iso}.png`} // Fallback image
                    alt={`${getSelectedCountry.iso}`}
                    style={{ width: '2rem', height: 'auto' }}
                />}
                <input
                    autoComplete='off'
                    type='text'
                    disabled={getSelectedState === undefined}
                    onFocus={() => setIsFocused(true)}
                    style={{
                        width: 0,
                        boxSizing: 'border-box',
                        flexGrow: 1,
                        background: Colors.theme.white.white90,
                        paddingInline: '0.64rem',
                        paddingBlock: '0.32rem',
                        borderRadius: '0.4rem',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                        lineHeight: '1.68rem',
                        cursor: (getSelectedState === undefined) ? "not-allowed !important" : "pointer"
                    }}
                    value={getSearchText}
                    onChange={handleChangeInput}

                />
            </div>
            {

                <DropdownBox
                    isDropdownOpen={getIsFocused}
                >
                    {getStateIsSearching &&
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <SpinnerIcon />
                        </div>
                    }
                    {!getStateIsSearching && (getResponseAllCity.length === 0) &&
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {confComps.translate.notFound}
                        </div>
                    }
                    {getResponseAllCity.flatMap((city, index, arr) => {
                        if ((index + 1) === arr.length) {
                            return (
                                [<RowOfList onClick={() => {
                                    setSearchText(city.label);
                                    setSelectedCity(city);
                                    setIsFocused(false);
                                }}>
                                    {getSelectedCountry?.iso && <img
                                        src={`https://flagcdn.com/w320/${getSelectedCountry?.iso}.png`} // Fallback image
                                        alt={`${getSelectedCountry.iso}`}
                                        style={{ width: '1rem', height: 'auto' }}
                                    />}
                                    {!getSelectedCountry?.iso && '??  '}
                                    {city.label}
                                </RowOfList>
                                ]
                            )
                        }
                        return (
                            [<RowOfList onClick={() => {
                                setSearchText(city.label);
                                setSelectedCity(city);
                                setIsFocused(false);
                            }}>
                                {getSelectedCountry?.iso && <img
                                    src={`https://flagcdn.com/w320/${getSelectedCountry?.iso}.png`} // Fallback image
                                    alt={`${getSelectedCountry.iso}`}
                                    style={{ width: '1rem', height: 'auto' }}
                                />}
                                {!getSelectedCountry?.iso && '??  '}
                                {city.label}
                            </RowOfList>
                                ,
                            <LineSeparator />]

                        );
                    })
                    }
                </DropdownBox>


            }
        </div>

    );
}
export default SelectCity;