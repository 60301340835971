import { styled } from "@mui/material";
import Colors from "../../../config/Colors";

export const MainCard = styled('div')(({ theme }) => ({
    width: "100%",
    borderRadius: "0.5rem",
    boxShadow: Colors.shadow.dropShadow,
    boxSizing: "border-box",
    paddingInline: "1.44rem",
    paddingBlock: "0.8rem",
    [theme.breakpoints.up('xs')]: {
        paddingInline: "2.44rem",
        paddingBlock: "1rem",
    },
    [theme.breakpoints.up('sm')]: {
        paddingInline: "3.44rem",
        paddingBlock: "1.84rem",
    },
    [theme.breakpoints.up('md')]: {
        paddingInline: "3.74rem",
        paddingBlock: "2.14rem",

    },
    [theme.breakpoints.up('lg')]: {
        paddingInline: "3.84rem",
        paddingBlock: "2.24rem",
    },
    [theme.breakpoints.up('xl')]: {
        paddingInline: "3.84rem",
        paddingBlock: "2.24rem",
    },

}));